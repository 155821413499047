import _ from 'lodash'
import React, { HTMLAttributes } from 'react'

import { LocalizationKey } from '../lib'
import { fragment } from './fragment'
import { MixedVars } from './interpolate'

type AllowedElement =
  | 'div'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'span'

export function __<El extends AllowedElement>(
  phraseKey: LocalizationKey,
  variables: Record<string, MixedVars> = {},
  // @ts-ignore
  as: El = 'span',
  attributes: HTMLAttributes<El> = {}
): JSX.Element {
  return React.createElement(
    as,
    { ...attributes, 'data-l10n-key': phraseKey },
    fragment(phraseKey, variables)
  )
}
