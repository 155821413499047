import { currentLanguage } from '../lib'
import { formatDate } from './formatDate'
import { string } from './string'
import { DateTimeInput, tryCastDate } from './utils'

/**
 * Returns a string of hours and minutes, or an empty string if the given input is not valid.
 */
export function time(input: DateTimeInput, secondPartVisible = false): string {
  const time = getTimeFormat()

  const date = tryCastDate(input)
  if (date === null) {
    return ''
  }

  return formatDate(
    date,
    string('format2.time', { time }).replace(
      'mm',
      secondPartVisible ? 'mm:ss' : 'mm'
    ),
    {
      locale: currentLanguage.dateLocalizer,
    }
  )
}

const timeFormat: { current: string | undefined } = { current: undefined }
export function getTimeFormat() {
  if (timeFormat.current === undefined) {
    setTimeFormat(true)
  }

  return timeFormat.current!
}

export function setTimeFormat(twelveHourClock: boolean): void {
  timeFormat.current = twelveHourClock
    ? string('format2.time.12hr')
    : string('format2.time.24hr')
}
