import { currentLanguage } from '../lib'
import { formatDate } from './formatDate'
import { string } from './string'
import { DateTimeInput, tryCastDate } from './utils'

/**
 * Returns a string of month, or an empty string if the given input is not valid.
 */
export function month(input: DateTimeInput): string {
  const date = tryCastDate(input)
  if (date === null) {
    return ''
  }

  return formatDate(date, string('format2.month.short'), {
    locale: currentLanguage.dateLocalizer,
  })
}
