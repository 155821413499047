import { createFormatter } from '../Number'
import { string } from './string'

const currencyFunction = createFormatter({
  minDecimalPlace: NaN,
  maxDecimalPlace: 6,
  addThousandSeparators: true,
  largeNumberScales: [
    {
      scalingFactor: 1e12,
      delegate: (number) => string('number.format.trillion', { number }),
    },
    {
      scalingFactor: 1e9,
      delegate: (number) => string('number.format.billion', { number }),
    },
    {
      scalingFactor: 1e6,
      delegate: (number) => string('number.format.million', { number }),
    },
  ],
})

export function currency(input: number): string {
  return currencyFunction(input)
}
