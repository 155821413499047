import { Locale } from 'date-fns'

import Cn from './cn'
import De from './de'
import En from './en'
import Es from './es'
import Fr from './fr'
import Id from './id'
import It from './it'
import Ja from './ja'
import Ko from './ko'
import Pt from './pt'
import Sv from './sv'
import Th from './th'

// Keep untyped for AvailableLocale computation
const languages = [En, Fr, De, Es, Pt, It, Th, Id, Cn, Ko, Ja, Sv]

// Extract locale codes from untyped
export type AvailableLocale = (typeof languages)[number]['code']

export type Language = {
  readonly id: string
  readonly code: AvailableLocale
  readonly name: string
}

export type LoadableLanguage = Language & {
  readonly load: () => Promise<{
    dateLocalizer: Locale
    localeData: Record<string, string>
  }>
}

export type LoadedLanguage = Language & {
  readonly dateLocalizer: Locale
  readonly localeData: Record<string, string>
}

// Flatten the type for export
export function getLanguages(withSwedish = true): LoadableLanguage[] {
  return languages.filter(({ code }) => code !== 'sv' || withSwedish)
}

export function getLocales(removeSwedish = false): AvailableLocale[] {
  return getLanguages(removeSwedish).map(({ code }) => code)
}

export function isLocale(candidate: any): candidate is AvailableLocale {
  return getLocales().includes(candidate)
}
