import { createFormatter } from '../Number'
import { string } from './string'

const countFunction = createFormatter({
  minDecimalPlace: NaN,
  maxDecimalPlace: 2,
  addThousandSeparators: true,
  largeNumberScales: [
    {
      scalingFactor: 1e12,
      delegate: (number) => string('number.format.trillion', { number }),
    },
    {
      scalingFactor: 1e9,
      delegate: (number) => string('number.format.billion', { number }),
    },
    {
      scalingFactor: 1e6,
      delegate: (number) => string('number.format.million', { number }),
    },
    {
      scalingFactor: 1e3,
      startingValue: 1e4,
      delegate: (number) => string('number.format.kilo', { number }),
    },
  ],
})

export function count(input: number): string {
  return countFunction(Math.round(input))
}
