import { currentLanguage } from './lib'

const THOUSAND_GROUP_SEPARATORS = {
  en: ',',
  es: '.',
  pt: '.',
}

export const DECIMAL_PLACE_SEPARATORS = {
  en: '.',
  es: ',',
  pt: ',',
}

type LargeScaleNumber = {
  startingValue?: number
  scalingFactor: number
  delegate: (number: string) => string
}

export function createFormatter(
  options: NumberFormatterOptions
): ConfiguredNumberFormatter {
  return (input) => format(input, options)
}

export type NumberFormatter = typeof format

export type NumberFormatterInput = Parameters<typeof format>[0]

export type NumberFormatterOutput = ReturnType<typeof format>

export type NumberFormatterOptions = {
  minDecimalPlace: number
  maxDecimalPlace: number
  addThousandSeparators: boolean
  largeNumberScales?: LargeScaleNumber[]
  spare?: string
}

export type ConfiguredNumberFormatter = (input: NumberFormatterInput) => string

export function format(
  input: number | string,
  {
    minDecimalPlace,
    maxDecimalPlace,
    addThousandSeparators,
    largeNumberScales,
    spare,
  }: Partial<NumberFormatterOptions> = {}
): string {
  const value = typeof input === 'string' ? parseFloat(input) : input
  if (input === null || isNaN(value) || !isFinite(value)) {
    return spare || ''
  }

  const isNegativeNumber = value < 0
  const thousandGroupSeparator =
    THOUSAND_GROUP_SEPARATORS[currentLanguage.code] === undefined
      ? THOUSAND_GROUP_SEPARATORS.en
      : THOUSAND_GROUP_SEPARATORS[currentLanguage.code]
  const decimalPlaceSeparator =
    DECIMAL_PLACE_SEPARATORS[currentLanguage.code] === undefined
      ? DECIMAL_PLACE_SEPARATORS.en
      : DECIMAL_PLACE_SEPARATORS[currentLanguage.code]

  let prime = Math.abs(value)

  // Normalizes the value according to the large number scale
  const largeNumberEntry = largeNumberScales
    ? largeNumberScales.find(
        (entry) =>
          prime >=
          (entry.startingValue === undefined
            ? entry.scalingFactor
            : entry.startingValue)
      )
    : null

  if (largeNumberEntry) {
    prime = prime / largeNumberEntry.scalingFactor
  }

  // Rounds the integral part
  const integralPartNumber = Math.floor(prime)

  // Determines the decimal place number
  const mantissaPartString = getMantissa(String(prime))
  let decimalPlaceNumber = mantissaPartString.length
  if (minDecimalPlace !== undefined && minDecimalPlace >= 0) {
    decimalPlaceNumber = Math.max(decimalPlaceNumber, minDecimalPlace)
  }

  if (maxDecimalPlace !== undefined && maxDecimalPlace >= 0) {
    decimalPlaceNumber = Math.min(decimalPlaceNumber, maxDecimalPlace)
  }

  // Rounds the fraction part
  let mantissaPartNumber = 0
  if (mantissaPartString) {
    if (mantissaPartString.length > decimalPlaceNumber) {
      // Rounds down always
      mantissaPartNumber =
        Math.floor(
          parseFloat(
            mantissaPartString.substring(0, decimalPlaceNumber) +
              '.' +
              mantissaPartString.substring(decimalPlaceNumber)
          )
        ) / Math.pow(10, decimalPlaceNumber)
    } else {
      mantissaPartNumber = parseFloat('0.' + mantissaPartString)
    }
  }

  // Inserts thousand separators to the integral part
  let integralPartText
  if (addThousandSeparators) {
    integralPartText = integralPartNumber
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, thousandGroupSeparator)
  } else {
    integralPartText = integralPartNumber.toString()
  }

  // Adds a decimal place sign before the mantissa part
  let mantissaPartText = ''
  if (decimalPlaceNumber > 0) {
    mantissaPartText =
      decimalPlaceSeparator +
      getMantissa(mantissaPartNumber.toFixed(decimalPlaceNumber))
  }

  // Combines parts together
  let whole = integralPartText + mantissaPartText
  // Adds a minus sign
  if (isNegativeNumber && whole !== '0') {
    whole = '-' + whole
  }

  // Adds the large number notation
  if (largeNumberEntry) {
    whole = largeNumberEntry.delegate(whole)
  }

  return whole
}

function getMantissa(prime: string) {
  const matches = /\.(\d+)$/.exec(prime)
  if (matches) {
    return matches[1]
  }

  return ''
}
