import React from 'react'

import { LocalizationKey, getPhrase } from '../lib'
import { MixedVars, mapParts } from './interpolate'

export type TemplatePlaceholderRenderer = (
  value: React.ReactNode,
  key: string
) => MixedVars

export type TemplateTextRenderer = (part: string, index: number) => MixedVars

export type RenderFunction = (
  variables: Record<string, MixedVars>,
  renderers: {
    placeholder: TemplatePlaceholderRenderer
    text: TemplateTextRenderer
  }
) => MixedVars[]

export type TemplateRenderer = {
  render: RenderFunction
}

const DEFAULT_PLACEHOLDER_RENDERER: TemplatePlaceholderRenderer = (
  value,
  key
) => (
  <React.Fragment key={key} data-key={key}>
    {value}
  </React.Fragment>
)

const DEFAULT_TEXT_RENDERER: TemplateTextRenderer = (string, index) => (
  <React.Fragment key={`#${index}`}>{string}</React.Fragment>
)

export function template(phraseKey: LocalizationKey) {
  const renderer: TemplateRenderer = {
    render(
      variables,
      {
        placeholder = DEFAULT_PLACEHOLDER_RENDERER,
        text = DEFAULT_TEXT_RENDERER,
      }
    ) {
      const message = getPhrase(phraseKey)

      return mapParts(message, ({ stringOrVar, varName, index }) => {
        return varName
          ? placeholder(variables?.[varName] ?? varName, varName)
          : text(stringOrVar, index)
      })
    },
  }

  return renderer
}
