import _ from 'lodash'
import React from 'react'

import { getPhrase } from '../lib'
import { MixedVars } from './interpolate'

export const SEPARATOR_KEY = 'format.list.separator'
export const AND_KEY = 'format.list.and'

// Separated for readability
function insertInBetween<T>(array: T[], separator: string): (string | T)[] {
  return _.flatMap(array, (item) => [item, separator]).slice(0, -1)
}

export function enumerate<T extends MixedVars>(list: T[]): string | T {
  if (list.length === 0) {
    return ''
  } else if (list.length === 1) {
    return list[0]
  }

  const separator = getPhrase(SEPARATOR_KEY).replace(/\\s/g, ' ')
  const and = getPhrase(AND_KEY).replace(/\\s/g, ' ')

  const lastItem = list.pop()!

  // prettier-ignore
  // (much better readability with \n than all in one line)
  const enumeratedList: (string | T)[] = [
    ...insertInBetween(list, separator),
    and,
    lastItem,
  ]

  return (
    enumeratedList.every(_.isString) ? (
      enumeratedList.join('')
    ) : (
      <React.Fragment>{...enumeratedList}</React.Fragment>
    )
  ) as T
}
