import { currentLanguage } from '../lib'
import { formatDate } from './formatDate'
import { string } from './string'
import { getTimeFormat } from './time'
import { DateTimeInput, tryCastDate } from './utils'

/**
 * Returns a string of day-of-month, month, possibly year (depends on the second parameter), hours and minutes, or an empty string if the given input is not valid.
 */
export function dateTime(
  input: DateTimeInput,
  yearPartVisible = false
): string {
  const presentYear = new Date().getFullYear()
  const timeFormat = getTimeFormat()

  const date = tryCastDate(input)
  if (date === null) {
    return ''
  }

  const format =
    yearPartVisible || date.getFullYear() !== presentYear
      ? string('format2.date_time.long', { time: timeFormat })
      : string('format2.date_time.short', { time: timeFormat })

  return formatDate(date, format, { locale: currentLanguage.dateLocalizer })
}
