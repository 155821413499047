import {
  differenceInCalendarDays,
  isValid as isValidDate,
  addDays,
  subDays,
} from 'date-fns'
import _ from 'lodash'

export type DateTimeInput = Date | string | number | null | undefined

/**
 * Returns a valid Date object by trying to convert the given input to a Date object, otherwise null.
 */
export function tryCastDate(input: DateTimeInput): Date | null {
  if (!input) {
    return null
  }

  const output = new Date(input)
  return isValidDate(output) ? output : null
}

export function getWeekNumber(date: Date): { week: number; year: number } {
  const thisWeek = subDays(date, date.getDay())
  const firstWeek = getFirstWeekByDate(date)
  const days = differenceInCalendarDays(thisWeek, firstWeek.date)

  return {
    week: days / 7 + 1,
    year: firstWeek.year,
  }
}

export function getFirstWeekByYear(year: number): Date {
  const firstDayOfCurrentYear = new Date(year + '-01-01')
  if (firstDayOfCurrentYear.getDay() >= 4 /* Thursday */) {
    // Use 2nd week of Jan because 1st Jan falls into last week of previous year
    return addDays(firstDayOfCurrentYear, 7 - firstDayOfCurrentYear.getDay())
  }

  return subDays(firstDayOfCurrentYear, firstDayOfCurrentYear.getDay())
}

export function getFirstWeekByDate(date: Date): { date: Date; year: number } {
  const firstDayOfWeek = subDays(date, date.getDay())
  const lastDayOfWeek = addDays(date, 6 - date.getDay())
  if (firstDayOfWeek.getFullYear() === lastDayOfWeek.getFullYear()) {
    return {
      date: getFirstWeekByYear(firstDayOfWeek.getFullYear()),
      year: firstDayOfWeek.getFullYear(),
    }
  }

  const pastYear = {
    date: getFirstWeekByYear(firstDayOfWeek.getFullYear()),
    year: firstDayOfWeek.getFullYear(),
  }

  const thisYear = {
    date: getFirstWeekByYear(lastDayOfWeek.getFullYear()),
    year: lastDayOfWeek.getFullYear(),
  }

  if (date < thisYear.date) {
    return pastYear
  }

  return thisYear
}
