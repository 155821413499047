export default {
  code: 'ko',
  id: 'ko-KR',
  name: '한국어',
  async load() {
    const [dateLocalizer, { default: localeData }] = await Promise.all([
      import('date-fns/locale/ko').then(({ ko }) => ko),
      import('./data.json'),
    ])

    return {
      dateLocalizer,
      localeData,
    }
  },
} as const
