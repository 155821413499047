import React from 'react'

import { LocalizationKey, getPhrase } from '../lib'
import { MixedVars, interpolate } from './interpolate'

export function fragment(
  phraseKey: LocalizationKey,
  variables: Record<string, MixedVars>
): JSX.Element {
  const message = getPhrase(phraseKey)
  return <React.Fragment>{...interpolate(message, variables)}</React.Fragment>
}
