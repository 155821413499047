export default {
  code: 'pt',
  id: 'pt-PT',
  name: 'Português',
  async load() {
    const [dateLocalizer, { default: localeData }] = await Promise.all([
      import('date-fns/locale/pt').then(({ pt }) => pt),
      import('./data.json'),
    ])

    return {
      dateLocalizer,
      localeData,
    }
  },
} as const
