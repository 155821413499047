import { differenceInYears, isSameDay } from 'date-fns'
import _ from 'lodash'

import { date } from './date'
import { DateTimeInput, tryCastDate } from './utils'

/**
 * Returns a string of date range in the shortest form (depends on the second parameter), or an empty string if the given input is not valid.
 */
export function dateRange(
  start: DateTimeInput,
  end: DateTimeInput,
  yearPartVisible?: boolean
): string {
  const presentYear = new Date().getFullYear()

  const [m1, m2] = _.chain([start, end])
    .map(tryCastDate)
    .compact()
    .sortBy((m) => m.toISOString()) // Prevent the case of start date coming after due date
    .value()

  if (!m1 || !m2) {
    return ''
  }

  if (isSameDay(m1, m2)) {
    return date(m1, yearPartVisible)
  }

  const floatingYearDiff = differenceInYears(m2, m1)
  if (floatingYearDiff >= 2) {
    return date(m1, true) + ' - ' + date(m2, true)
  }

  if (floatingYearDiff >= 1) {
    return (
      date(m1, true) +
      ' - ' +
      date(m2, m2.getFullYear() === presentYear ? yearPartVisible : true)
    )
  }

  return (
    date(m1, false) +
    ' - ' +
    date(m2, m2.getFullYear() === presentYear ? yearPartVisible : true)
  )
}
