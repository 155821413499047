import _ from 'lodash'

export const POLYGLOT_REGEXP = /(%\{[^%]+\})/g

export type StringVars = string

export type MixedVars = string | JSX.Element

export type L10nVars = StringVars | MixedVars

function isVar(str: string) {
  // Faster than a regexp test
  return str.startsWith('%{') && str.endsWith('}') && str.length > 3
}

function getVarName(str: string) {
  // Faster than a regexp extraction
  return isVar(str) ? str.slice(2, -1) : undefined
}

type PartMapperFn<T extends L10nVars> = (args: {
  stringOrVar: string
  index: number
  varName: ReturnType<typeof getVarName>
}) => T

export function mapParts<T extends L10nVars>(
  message: string,
  map: PartMapperFn<T>
): T[] {
  return message
    .split(POLYGLOT_REGEXP)
    .filter(Boolean)
    .map((stringOrVar, index) =>
      map({ stringOrVar, index, varName: getVarName(stringOrVar) })
    )
}

// If the `variables` passed do not contain a react node, then it will return
// a single string. otherwise it will return chunks of React.ReactNode
export function interpolate<T extends L10nVars>(
  message: string,
  variables: Record<string, T>
): (string | T)[] {
  return mapParts(message, ({ stringOrVar, varName }) => {
    const possibleValue = varName ? variables[varName] : undefined
    return possibleValue ?? stringOrVar
  })
}
