export function maskEmail(input: string): string {
  const [username, domain, ...others] = input.trim().split('@')
  if (!username || !domain || others.length > 0) {
    return input
  }

  return username
    .split('')
    .map((character, index) => {
      if (username.length >= 6 && index < 4) {
        return character
      }

      if (index < 1) {
        return character
      }

      return '*'
    })
    .join('')
    .concat('@')
    .concat(domain)
}
