export default {
  code: 'sv',
  id: 'sv-SE',
  name: 'Svenska',
  async load() {
    const [dateLocalizer, { default: localeData }] = await Promise.all([
      import('date-fns/locale/sv').then(({ sv }) => sv),
      import('./data.json'),
    ])

    return {
      dateLocalizer,
      localeData,
    }
  },
} as const
