import { currentLanguage } from '../lib'
import { formatDate } from './formatDate'
import { DateTimeInput, tryCastDate } from './utils'

export function dayOfWeek(input: DateTimeInput): string {
  const date = tryCastDate(input)
  if (date === null) {
    return ''
  }

  return formatDate(date, 'eee', { locale: currentLanguage.dateLocalizer })
}
