import { currentLanguage } from '../lib'
import { formatDate } from './formatDate'
import { string } from './string'
import { DateTimeInput, tryCastDate } from './utils'

/**
 * Returns a string of day-of-month, month, and possibly year (depends on the second parameter), or an empty string if the given input is not valid.
 */
export function date(input: DateTimeInput, yearPartVisible?: boolean): string {
  const date = tryCastDate(input)
  if (date === null) {
    return ''
  }

  const presentYear = new Date().getFullYear()
  const format =
    yearPartVisible ||
    (yearPartVisible === undefined && date.getFullYear() !== presentYear)
      ? string('format2.date.long')
      : string('format2.date.short')

  return formatDate(date, format, { locale: currentLanguage.dateLocalizer })
}
