import _ from 'lodash'

import { getLanguages, AvailableLocale, LoadedLanguage } from './langs'
import En from './langs/en'

const defaultLanguage: LoadedLanguage = En
export let currentLanguage = { ...defaultLanguage }

// We statically use `en` as a reference since it's supposed to be
// our fallback languages containing the whole set of keys
export type LocalizationKey = keyof (typeof En)['localeData']

// This allows to have a list of known keys while allowing unknown keys
export type Dictionary = Record<LocalizationKey, string> & {
  [key: string]: string
}

export async function switchLanguage(
  code: AvailableLocale,
  withSwedish = true
) {
  const desiredLanguage = getLanguages(withSwedish).find(
    (lang) => lang.code === code
  )

  if (desiredLanguage) {
    currentLanguage = {
      ...desiredLanguage, // Also copying load(), but who cares?
      ...(await desiredLanguage.load()),
    }
  } else {
    currentLanguage = defaultLanguage
  }

  const { documentElement } = window?.document ?? {}
  if (documentElement) {
    documentElement.lang = currentLanguage.code
    documentElement.setAttribute('lang', currentLanguage.id)
  }
}

export function getPhrase(
  phraseKey: LocalizationKey,
  fallbackValue = phraseKey
) {
  return (
    currentLanguage.localeData[phraseKey] ??
    defaultLanguage.localeData[phraseKey] ??
    fallbackValue
  )
}

// Should not be used aside from testing.
export function setDictionary<T extends Record<string, string>>(localeData: T) {
  currentLanguage = { ...currentLanguage, localeData }
}
