import _ from 'lodash'

import { createFormatter } from '../Number'

const percentFunction = createFormatter({
  minDecimalPlace: 0,
  maxDecimalPlace: 2,
  addThousandSeparators: false,
})

export function percent(input: number, total: number = 100): string {
  if (total === 0) {
    return percentFunction(0)
  }

  const floatingPercent = (input / total) * 100
  if (floatingPercent < 0) {
    return percentFunction(
      floatingPercent > -1
        ? Math.floor(floatingPercent)
        : Math.ceil(floatingPercent)
    )
  }

  return percentFunction(
    floatingPercent < 1
      ? Math.ceil(floatingPercent)
      : Math.floor(floatingPercent)
  )
}

percent.from = function (numbers: number[] = []): number[] {
  let totalPercent = 100
  let numberData = numbers.map((num, index) => ({
    index,
    value: num,
    result: 0,
  }))
  const sum = _.sum(numbers)
  numberData = _.sortBy(numberData, ({ value }) => value)

  const lastIndex = numberData.length - 1
  numberData.forEach((data, index) => {
    if (data.value > 0) {
      if (index === lastIndex) {
        data.result = totalPercent
      } else {
        const result = Math.max(1, Math.round((data.value * 100) / sum))
        totalPercent -= result
        data.result = result
      }
    }
  })
  numberData = _.sortBy(numberData, ({ index }) => index)

  return numberData.map((num) => num.result)
}
