import { string } from './string'
import { DateTimeInput, getWeekNumber, tryCastDate } from './utils'

/**
 * Returns a string of week-in-a-year number, or an empty string if the given input is not valid.
 * @see https://savvytime.com/week-number/united-states/2021
 */
export function week(input: DateTimeInput): string {
  const date = tryCastDate(input)
  if (date === null) {
    return ''
  }

  // Do not use functions from date-fns because of the known issue https://github.com/date-fns/date-fns/issues/2077
  const { week } = getWeekNumber(date)
  return string('format2.week.short.us', {
    weekNumber: String(week),
  })
}
