export default {
  code: 'fr',
  id: 'fr-FR',
  name: 'Français',
  async load() {
    const [dateLocalizer, { default: localeData }] = await Promise.all([
      import('date-fns/locale/fr').then(({ fr }) => fr),
      import('./data.json'),
    ])

    return {
      dateLocalizer,
      localeData,
    }
  },
} as const
