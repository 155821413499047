import { currentLanguage } from '../lib'
import { formatDate } from './formatDate'
import { string } from './string'
import { DateTimeInput, tryCastDate } from './utils'

/**
 * Returns a string of month and possibly year (depends on the second parameter), or an empty string if the given input is not valid.
 */
export function monthYear(
  input: DateTimeInput,
  yearPartVisible?: boolean
): string {
  const presentYear = new Date().getFullYear()

  const date = tryCastDate(input)
  if (date === null) {
    return ''
  }

  const format =
    yearPartVisible ||
    (yearPartVisible === undefined && date.getFullYear() !== presentYear)
      ? string('format2.month.long')
      : string('format2.month.short')

  return formatDate(date, format, { locale: currentLanguage.dateLocalizer })
}
