import { __ } from './__'
import { byte } from './byte'
import { count } from './count'
import { currency } from './currency'
import { date } from './date'
import { dateRange } from './dateRange'
import { dateTime } from './dateTime'
import { dayOfWeek } from './dayOfWeek'
import { dual } from './dual'
import { enumerate } from './enumerate'
import { fragment } from './fragment'
import { maskEmail } from './maskEmail'
import { month } from './month'
import { monthYear } from './monthYear'
import { percent } from './percent'
import { quarterYear } from './quarterYear'
import { string } from './string'
import { template } from './template'
import { time } from './time'
import { week } from './week'
import { weekYear } from './weekYear'
import { year } from './year'

export * from './__'
export * from './enumerate'
export * from './byte'
export * from './count'
export * from './currency'
export * from './date'
export * from './dateRange'
export * from './dateTime'
export * from './dayOfWeek'
export * from './dual'
export * from './formatDate'
export * from './fragment'
export * from './maskEmail'
export * from './month'
export * from './monthYear'
export * from './percent'
export * from './quarterYear'
export * from './string'
export * from './template'
export * from './time'
export * from './week'
export * from './weekYear'
export * from './year'

const extensions = {
  byte,
  count,
  currency,
  date,
  dateRange,
  dateTime,
  dayOfWeek,
  dual,
  enumerate,
  fragment,
  maskEmail,
  month,
  monthYear,
  percent,
  quarterYear,
  string,
  template,
  time,
  week,
  weekYear,
  year,
}

type DoubleUnderscore = typeof __
type Extensions = typeof extensions

// Overload __ function with sub functions
Object.assign(__, extensions)

export default __ as DoubleUnderscore & Extensions
