import { string } from './string'
import { DateTimeInput, getWeekNumber, tryCastDate } from './utils'

/**
 * Returns a string of week-in-a-year number and year, or an empty string if the given input is not valid.
 * @example __.weekYear(new Date('2021-01-01')) // yields 'Week 53, 2020'
 * @see https://savvytime.com/week-number/united-states/2021
 */
export function weekYear(input: DateTimeInput): string {
  const date = tryCastDate(input)
  if (date === null) {
    return ''
  }

  const { week, year } = getWeekNumber(date)
  return string('format2.week.long.us', {
    weekNumber: String(week),
    year: String(year),
  })
}
