import { filesize, FileSizeOptionsBase } from 'filesize'
import _ from 'lodash'

import { currentLanguage } from '../lib'
import { DECIMAL_PLACE_SEPARATORS } from '../Number'

/**
 * @see https://www.npmjs.com/package/filesize
 */
export function byte(
  input: number | undefined,
  options: FileSizeOptionsBase = {}
): string {
  // These 2 cases will make filesize() throw an error
  if (!_.isNumber(input) || _.isNaN(input)) {
    return ''
  }

  return filesize(input, {
    standard: 'iec',
    // // TODO: Override iec symbols only when this PR is released: https://github.com/avoidwork/filesize.js/pull/175
    // Override iec names with the ones used in the rest of the app
    symbols: {
      iB: 'B',
      KiB: 'KB',
      MiB: 'MB',
      GiB: 'GB',
      TiB: 'TB',
      PiB: 'PB',
      EiB: 'EB',
      ZiB: 'ZB',
      YiB: 'YB',
    },
    ...options,
    separator:
      DECIMAL_PLACE_SEPARATORS[currentLanguage.code] ||
      DECIMAL_PLACE_SEPARATORS.en,
  } as FileSizeOptionsBase)
}
