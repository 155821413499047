import { LocalizationKey, getPhrase } from '../lib'
import { StringVars, interpolate } from './interpolate'

export function string(
  phraseKey: LocalizationKey,
  variables: Record<string, StringVars> = {}
): string {
  const message = getPhrase(phraseKey)
  return interpolate(message, variables).join('')
}
